.intro-section {
  padding-top: 120px;
  padding-bottom: 50px;
}

.intro-text {
  padding: 1em 2em;
  border-radius: 10px;
  overflow: hidden;
  font-style: italic;
  font-weight: 700;
  font-size: 1.1em;
  background-color: #edf5ff;
  margin: 0 auto 40px;
  color: #46467b;
}

.intro-outer {
  width: 100vw;
  overflow: scroll;
  scrollbar-width: none;
}

.intro-outer::-webkit-scrollbar {
  display: none;
}

.intro-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.intro-col {
  width: 320px;
  margin: 0 auto;
}

.intro-tools canvas {
  opacity: 0.9;
  transition: 0.35s;
}

.intro-tools canvas:hover {
  opacity: 1;
}

@media screen and (max-width: 500px) {
  .intro-text {
    padding: 20px;
    font-size: 0.9em;
  }
}

@media print {
  .intro-section {
    padding-top: 80px;
  }
  .intro-text {
    -webkit-print-color-adjust: exact;
    scale: 0.75;
    margin-bottom: 0;
  }
  .intro-col canvas {
    scale: 0.65;
    margin-top: -50px;
  }
}