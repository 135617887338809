.contact {
  background-color: #6E21EC;
  color: #fff;
  margin-top: 50px;
}

.contact a {
  color: #fff;
  text-decoration: none;
}

.contact a:hover {
  text-decoration: underline;
}

.contact-container {
  display: flex;
  justify-content: space-around;
  text-align: center;
}

@media print {
  .contact {
    display: none;
  }
}