.container {
  max-width: 960px;
  margin: 0 auto;
  padding: 0 40px;
}

@media screen and (max-width: 500px) {
  .container {
    padding: 0 10px;
  }
}