.printicon {
  position: absolute;
  top: 50px;
  right: 50px;
  appearance: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: block;
}

@media screen and (max-width: 500px ) {
  .printicon {
    scale: 0.65;
    top: 10px;
    right: 10px;
  }
}

@media print {
  .printicon {
    display: none;
  }
}

