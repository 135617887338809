.printfooter {
  background-color: #6E21EC;
  -webkit-print-color-adjust: exact;
  color: #fff;
  display: none;
}

.printfooter-container {
  display: flex;
  justify-content: space-around;
}

@media print {
  .printfooter {
    display: block;
  }
}