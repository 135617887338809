.hero {
  height: 500px;
  background-color: #6E21EC;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
}

.hero h1 {
  font-size: 10vw;
  font-weight: 800;
  margin: 0;
}

.hero .malte-photo {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 200px;
}

.hero .malte-photo img {
  display: block;
  width: 100%;
  border-radius: 200px;
}

.hero h3 {
  position: absolute;
  margin: 0;
  padding: 0.5em 2em;
  border-radius: 2em;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  background-color: #576574;
  font-size: 1em;
  white-space: nowrap;
  box-shadow: 0em 1em 1em #00000057;
}

@media screen and (max-width: 500px) {
  .hero {
    height: 400px;
  }
}

@media print {
  .hero {
    height: 200px;
    -webkit-print-color-adjust: exact;
  }
  .hero h1 {
    font-size: 3em;
  }
  .hero .malte-photo {
    width: 120px;
  }
  .hero .malte-photo img {
    width: 120px;
  }
  .hero h3 {
    transform: translate(-50%, 50%) scale(0.75);
    box-shadow: none;
    -webkit-print-color-adjust: exact;
  
  }
}
