.job-container {
  padding: 2em 0;
  display: flex;
}

.job-heading { 
  margin: 0;
  color: #586574;
}

.job-title {
  margin: 0;
}

.job-date { font-size: small; }

.job-circle {
  background-color: #000;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 80px;
  margin-right: 1em;
  font-weight: 700;
  color: #fff;
  -webkit-print-color-adjust: exact;
}

@media screen and (max-width: 500px) {
  .job-circle {
    width: 40px;
    height: 40px;
    font-size: 0.6em;
  } 
  .job-text {
    font-size: 16px;
  }
}

@media print {
  .job-container {
    font-size: 12px;
  }
  .job-circle {
    margin-right: 40px;
  }
}